import React from "react";

export default function Footer() {
  return (
    <footer className="bg-light mt-auto pt-3 text-secondary">
      <div className="container-xxl d-flex justify-content-between">
        <p>© <a className="link-secondary" href="https://kpi.fei.tuke.sk/">Katedra počítačov a informatiky FEI
          TUKE</a></p>
        <p>Autor: <a className="link-secondary" href="https://kpi.fei.tuke.sk/sk/person/sergej-chodarev">Sergej
          Chodarev</a>,
          štyl a ikony: <a className="link-secondary" href="https://getbootstrap.com/">Bootstrap</a></p>
      </div>
    </footer>
  );
}
