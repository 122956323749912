import React from "react";
import ReportField from "./ReportField";

export default function StudentReport(props) {
  return <div key={props.index} className="my-4">
    <h3 className="h5 fw-bold">{props.student.name}</h3>
    <h4 className="h6 fw-bold text-body-secondary">{props.student.project}</h4>
    <ReportField label="Čo sa vám podarilo urobiť tento týždeň?" text={props.student.data.done}/>
    <ReportField label="Čo máte v úmysle urobiť budúci týždeň?" text={props.student.data.todo}/>
  </div>;
}
