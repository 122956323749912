import React, {useCallback, useEffect, useState} from "react";
import {getCookie} from '../functions/getCookie';
import {useParams} from "react-router-dom";
import {Button, Col, Form, Row, Spinner} from "reactstrap";
import SettingsForm from "./components/settings/SettingsForm";

const Settings = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState({});
  const [supervisors, setSupervisors] = useState({});
  const [team, setTeam] = useState({});
  const [addStudents, setAddStudents] = useState({team_id: id, students: []});
  const [addSupervisors, setAddSupervisors] = useState({team_id: id, supervisors: []})

  const getStudents = useCallback(async () => {
    try {
      const response = await fetch(`/api/teams/${id}/students/`, {
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
          "Cookie": `sessionid=${getCookie("sessionid")}`,
        },
      });
      if (response.ok) {
        const supervisors_students_data = await response.json();
        setStudents(supervisors_students_data.supervisors);
        setLoading(false);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);


  const getTeam = useCallback(async () => {
    const response = await fetch(`/api/teams/${id}/`, {
      credentials: "include"
    });
    if (response.ok) {
      setTeam(await response.json());
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  }, [id]);

  const getSupervisors = useCallback(async () => {
    const response = await fetch(`/api/teams/${id}/supervisors/`, {
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-CSRFToken": getCookie("csrftoken"),
        "Cookie": `sessionid=${getCookie("sessionid")}`,
      },
    });

    if (response.ok) {
      setSupervisors(await response.json());
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  }, [id]);

  const handleChangeStudents = (userId) => (event) => {
    if (event.target.checked) {
      setAddStudents(prevState => ({
        ...prevState,
        students: [...prevState.students, userId]
      }));
    } else {
      setAddStudents(prevState => ({
        ...prevState,
        students: prevState.students.filter(id => id !== userId)
      }));
    }
  };

  const handleChangeSupervisors = (event) => {
    const selectedIds = Array.from(event.target.options)
      .filter(option => option.selected)
      .map(option => option.value);

    setAddSupervisors(prevState => {
      return {
        ...prevState,
        supervisors: selectedIds
      };
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    await submitStudents();
    await submitSupervisors();
    window.location.reload(); // Reload the page once all submissions are done
  };

  const submitStudents = async () => {
    const jsonStudents = JSON.stringify(addStudents);
    const urlStudents = `/api/teams/${id}/students/`;

    try {
      const response = await fetch(urlStudents, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: jsonStudents,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error posting students:', error);
    }
  };

  const submitSupervisors = async () => {
    const jsonSupervisors = JSON.stringify(addSupervisors);
    const urlSupervisors = `/api/teams/${id}/supervisors/`;

    try {
      const response = await fetch(urlSupervisors, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: jsonSupervisors,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error posting supervisors:', error);
    }
  };


  const handleCloseTeam = async () => {
    const url = `/api/teams/${id}/close/`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });
      if (response.ok) {
        window.location.reload();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error closing team:', error);
    }
  };

  useEffect(() => {
    getStudents();
    getTeam();
    getSupervisors();
  }, [getStudents, getTeam, getSupervisors]);

  return (
    <main className="container">
      {loading ? (
        <div className="text-center">
          <Spinner color="primary"/>
        </div>
      ) : (
        <div>
          <Row>
            <Col><h2>Nastavenie tímu {team.name}</h2></Col>
            <Col className="d-flex justify-content-end">
              <div>
                <Button className="btn-sm" color={team.active ? "danger" : "success"} onClick={handleCloseTeam}>
                  {team.active ? "Ukončiť tím" : "Obnoviť tím"}
                </Button>
              </div>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row style={{marginTop: 40}}>
              <SettingsForm students={students} supervisors={supervisors.supervisors}
                            handleChangeStudents={handleChangeStudents}
                            handleChangeSupervisors={handleChangeSupervisors}/>
            </Row>
            <Button className="btn-primary btn-sm" color="primary" type='submit' style={{marginTop: 30}}>
              Upraviť tím
            </Button>
          </Form>
        </div>
      )}
    </main>
  )
}


export default Settings;
