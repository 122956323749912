import React, {useEffect, useState} from "react";
import {Col, Row, Button, Spinner} from "reactstrap";
import TeamsGroup from "./components/teams/TeamsGroup";

function Teams() {
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const activeTeams = teams.filter(team => team.active);
  const inactiveTeams = teams.filter(team => !team.active);

  const getTeams = async () => {
    try {
      const response = await fetch(`/api/teams/`, {
        credentials: "include"
      });
      if (response.ok) {
        const data = await response.json();
        setTeams(data);
        setLoading(false);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const groupTeamsByYear = (teams) => {
    const groupedTeams = {};
    teams.forEach(team => {
      if (team.year === null) {
        if (!groupedTeams["Bez určeného roku"]) {
          groupedTeams["Bez určeného roku"] = [];
        }
        groupedTeams["Bez určeného roku"].push(team);
      } else {
        if (!groupedTeams[team.year]) {
          groupedTeams[team.year] = [];
        }
        groupedTeams[team.year].push(team);
      }
    });
    return groupedTeams;
  };

  const groupedActiveTeams = groupTeamsByYear(activeTeams);
  const groupedInactiveTeams = groupTeamsByYear(inactiveTeams);

  const handleCreateTeam = () => {
    window.location.href = "/create_workweek/";
  };

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <main className="container">
      {loading ? (
        <div className="text-center">
          <Spinner color="primary"/>
        </div>
      ) : (
        <div>
          <h1>Moje pracovné tímy</h1>
          <div className="mt-3">
            <Button onClick={handleCreateTeam} className="btn btn-primary btn-sm" color="primary">
              Vytvoriť Workweek
            </Button>
          </div>
          <Row>
            <Col>
              {Object.keys(groupedActiveTeams).length > 0 && (
                <TeamsGroup teams={groupedActiveTeams}
                            title={"Aktívne"}></TeamsGroup>
              )}
            </Col>
            <Col>
              {Object.keys(groupedInactiveTeams).length > 0 && (
                <TeamsGroup teams={groupedInactiveTeams}
                            title={"Neaktívne"}></TeamsGroup>
              )}
            </Col>
          </Row>
        </div>
      )}
    </main>
  );
}

export default Teams;
