import { replace } from "react-router-dom";

export async function projectLoader({ params }) {
  const response = await fetch(`/api/projects/${params.projectId}/`);
  if (!response.ok) throw response;
  return response.json();
}

export async function userProjectsLoader() {
  const response = await fetch("/api/projects/my/");
  if (!response.ok) throw response;
  return response.json();
}

export async function allProjectsLoader({ params }) {
  const response = await fetch(
    `/api/projects/?type=${params.type}&year=${params.year}`);
  if (!response.ok) throw response;
  return {
    projects: await response.json(),
  };
}

export async function termsLoader({ params }) {
  const response = await fetch(`/api/terms/`);
  if (!response.ok) throw response;
  if (!("type" in params) || !("year" in params)) {
    const terms = await response.json();
    return replace(
      `/projects/all/${terms[0].type}/${terms[0].year_finishing}`);
  }
  return {
    terms: await response.json()
  };
}

export async function milestonesLoader() {
  const response = await fetch(`/api/milestones/`);
  if (!response.ok) throw response;
  return response.json();
}

export async function createProjectLoader() {
  const [teachersResponse, termsResponse] = await Promise.all([
    fetch("/api/teachers"),
    fetch("/api/terms/enabled/")
  ]);
  if (!teachersResponse.ok) throw teachersResponse;
  if (!termsResponse.ok) throw termsResponse;

  const teachers = await teachersResponse.json();
  const terms = await termsResponse.json();
  return { teachers, terms };
}
