import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";
import StudentsFormInput from "./StudentsFormInput";

export default function SettingsForm(props) {
  return (
    <div>
      <Row>
        <Col>
          <h4>Pridať študentov</h4>
          {props.students &&
            Object.entries(props.students).map(([supervisorName, students]) => (
              <div key={supervisorName}>
                <h5 className="mt-3">{supervisorName}</h5>
                <StudentsFormInput students={students} studentsType="inactive"
                                   handleChangeStudents={props.handleChangeStudents}/>
              </div>
            ))}
        </Col>
        <Col>
          <h4>Odstrániť študentov</h4>
          {props.students &&
            Object.entries(props.students).map(([supervisorName, students]) => (
              <div key={supervisorName}>
                <h5 className="mt-3">{supervisorName}</h5>
                <StudentsFormInput students={students} studentsType="active"
                                   handleChangeStudents={props.handleChangeStudents}/>
              </div>
            ))}
        </Col>
      </Row>
      <Row className="mt-4">
        <h4>Spolupracovníci</h4>
        <Col>
          <h5>Neaktívny</h5>
          <FormGroup>
            <Input type="select" onChange={props.handleChangeSupervisors} multiple>
              {props.supervisors && props.supervisors["inactive"] && props.supervisors["inactive"].map(({id, name}) =>
                (<option key={id} value={id}>{name}</option>))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <h5>Aktívny</h5>
          <FormGroup>
            <Input type="select" onChange={props.handleChangeSupervisors} multiple>
              {props.supervisors && props.supervisors["active"] && props.supervisors["active"].map(({id, name}) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
