import { Container, Nav, Navbar } from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";

export default function Header({ user, teamId }) {
  return (
    <Navbar className="mb-3" bg="light" data-bs-theme="light" expand="lg">
      <Container fluid="xxl">
        <LinkContainer to={`/`}>
          <Navbar.Brand>Záverečné práce</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            {user && user.is_teacher && (
              <LinkContainer to={`/archive`}>
                <Nav.Link active={false}>Archív</Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to={`/milestones`}>
              <Nav.Link active={false}>Milestones</Nav.Link>
            </LinkContainer>
            {user && (
              <LinkContainer to={`/workweek`}>
                <Nav.Link active={false}>Workweek</Nav.Link>
              </LinkContainer>
            )}
            {user && user.is_teacher && teamId && (
              <LinkContainer to={`/workweek/${teamId}/settings`}>
                <Nav.Link active={false}>Spravovať tím</Nav.Link>
              </LinkContainer>
            )}
            {user && user.is_staff && (
              <LinkContainer to={`/projects/all`}>
                <Nav.Link active={false}>Všetky projekty</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
          {user && (
            <Navbar.Text>
              <PersonCircle size={20} className="align-text-bottom" />
              &nbsp;{user.full_name}
            </Navbar.Text>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
