import {ListGroup, ListGroupItem} from "reactstrap";
import {getFormattedDate} from "../../../functions/formatDate";
import React from "react";

export default function WeekSwitcher(props) {
  return <ListGroup className="text-center group-scroller">
    {props.dates.slice().reverse().map((date, index) => (
      <ListGroupItem key={index} action tag="button" active={props.activeButton === date}
                     onClick={
                       Object.keys(props.report).length > 0 && props.report.report_day && date === props.report.report_day && !props.user.is_teacher && props.showReportButton
                         ? props.handleAddReport
                         : () => props.handleClick(date)
                     }
      >
        {getFormattedDate(date)}
      </ListGroupItem>
    ))
    }</ListGroup>
}
