import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

export default function ReportFormField(props) {
  return <FormGroup className="mt-4">
    <Label for={props.id} className="fw-bold">
      {props.label}
    </Label>
    <Input id={props.id} name="text" type="textarea" rows="10" onChange={props.onChange} value={props.text}/>
  </FormGroup>;
}
