import { Button, ButtonGroup, Container } from "react-bootstrap";
import { Check, CheckAll } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { Outlet, useLoaderData } from "react-router-dom";

import "../assets/css/projects.css";
import { VersionsMarks } from "./components";


export function AllProjects() {
  const { terms } = useLoaderData();

  return <Container fluid="xxl">
    <h1 className="mb-4">Prehľad odovzdaní</h1>
    <ButtonGroup size="sm" className="mb-3">
      {terms.map(({ type, year_finishing }) =>
        <LinkContainer to={`/projects/all/${type}/${year_finishing}`} key={type + year_finishing}>
          <Button variant="outline-primary">{type} {year_finishing}</Button>
        </LinkContainer>
      )}
    </ButtonGroup>

    <Outlet />
  </Container>;
}

export function AllProjectsTable() {
  const { projects } = useLoaderData();

  let lastSupervisor;

  function formatSupervisor(supervisor) {
    if (supervisor?.id === lastSupervisor?.id) {
      return "";
    } else {
      lastSupervisor = supervisor;
      if (supervisor === null)
        return "--------";
      return supervisor.full_name;
    }
  }

  return <>
    <p>{projects.length} projektov</p>
    <p>
      <strong>Legenda:</strong><Check size={24} /> — odovzdané,
      <CheckAll size={24} /> — odovzdané so spätnou väzbou.
    </p>

    <table className="table table-borderless table-hover">
      <thead>
        <tr className="border-bottom border-dark">
          <th scope="col">Školiteľ</th>
          <th scope="col">Študent</th>
          <th scope="col">Názov</th>
          <th scope="col">Odovzdania</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project) =>
          <AllProjectsLine supervisor={formatSupervisor(project.supervisor)}
            project={project} key={project.id} />)}
      </tbody>
    </table>
  </>;
}

function AllProjectsLine({ supervisor, project }) {
  return <tr>
    <td>{supervisor}</td>
    <td>{project.student.full_name}</td>
    <td><a href={project.thesis_url}>{project.title}</a></td>
    <td className="text-nowrap">
      <VersionsMarks versions={project.versions} />
    </td>
  </tr>;
}
