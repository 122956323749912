export async function loadCreateWorkweek() {
  const response = await fetch(`/api/milestones/`);
  if (!response.ok)
    throw response;
  return response.json();
}


export function CreateWorkweek() {
  return <></>;
}
