import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  useLoaderData,
} from "react-router-dom";
import "./assets/css/workweek.css";
import Base from "./base/Base";
import ErrorPage from "./base/Error.js";
import {
  archiveProjectAction,
  unarchiveProjectAction,
} from "./projects/actions";
import { AllProjects, AllProjectsTable } from "./projects/AllProjects";
import CreateProject from "./projects/CreateProject";
import GettingStarted from "./projects/GettingStarted";
import * as loaders from "./projects/loaders.js";
import { rootLoader } from "./base/loaders";
import Milestones from "./projects/Milestones";
import { Projects, ProjectsArchive } from "./projects/Projects";
import { CreateWorkweek, loadCreateWorkweek } from "./workweek/CreateWorkweek";
import Reports from "./workweek/Reports";
import Settings from "./workweek/Settings";
import Teams from "./workweek/Teams";


function App() {
  const { user } = useLoaderData();
  return <Base user={user} />;
}

const router = createBrowserRouter([
  {
    id: "root",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      {
        path: "/",
        children: [
          {
            index: true,
            element: <Projects />,
            loader: loaders.userProjectsLoader,
          },
          {
            path: "archive/:projectId",
            action: archiveProjectAction,
          },
          {
            path: "unarchive/:projectId",
            action: unarchiveProjectAction,
          },
          {
            path: "archive",
            element: <ProjectsArchive />,
            loader: loaders.userProjectsLoader,
          },
          {
            path: "create",
            element: <CreateProject />,
            loader: loaders.createProjectLoader,
          },
          {
            path: "projects/:projectId/getting-started",
            element: <GettingStarted />,
            loader: loaders.projectLoader,
          },
          {
            path: "milestones",
            element: <Milestones />,
            loader: loaders.milestonesLoader,
          },
          {
            path: "projects/all",
            element: <AllProjects />,
            loader: loaders.termsLoader,
            children: [
              {
                path: ":type/:year",
                element: <AllProjectsTable />,
                loader: loaders.allProjectsLoader,
              },
            ],
          },
          {
            path: "workweek",
            children: [
              { index: true, element: <Teams /> },
              {
                path: "create",
                element: <CreateWorkweek />,
                loader: loadCreateWorkweek,
              },
              { path: ":id", element: <Reports /> },
              { path: ":id/settings", element: <Settings /> },
            ],
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />,
  // </React.StrictMode>
);
