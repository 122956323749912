import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

export default function StudentsFormInput(props) {
  return (
    <div>
      {props.students.students && props.students.students[props.studentsType] &&
        props.students.students[props.studentsType].map(({id, name, project_type, year}) => (
          <FormGroup check key={id}>
            <Label title={"Typ práce: " + project_type +"\nRok ukončenia: " + year} check>
              <Input type="checkbox" id={id} name={name} onChange={props.handleChangeStudents(id)}/>
              {name}
            </Label>
          </FormGroup>
        ))}
    </div>
  );
}
