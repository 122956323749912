import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, Form, Row, Col, Spinner} from "reactstrap";
import {getFormattedDate} from '../functions/formatDate';
import {getCookie} from "../functions/getCookie";
import WeekSwitcher from "./components/reports/WeekSwitcher";
import ReportFormField from "./components/reports/ReportFormField";
import StudentReport from "./components/reports/StudentReport";
import { useRouteLoaderData } from "react-router-dom";

const Reports = () => {
  const { user } = useRouteLoaderData("root");
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const [reports, setReports] = useState({});
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState();

  const [showReportForm, setShowReportForm] = useState(null);
  const [showReportButton, setShowReportButton] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const getReport = useCallback(async () => {
    try {
      const response = await fetch(`/api/reports/${user.id}/latest/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
          "Cookie": `sessionid=${getCookie("sessionid")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reportData = await response.json();
      setReport(reportData);

      const path = window.location.pathname;
      const teamId = path.split('/').pop();

      if (!user.is_teacher && user.workweek && user.workweek.team_id === parseInt(teamId)) {
        setShowReportForm(true);
        setShowReportButton(true);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [user.id, user.is_teacher, user.workweek]);

  const getReports = async () => {
    try {
      const path = window.location.pathname;
      const teamId = path.split('/').pop();
      const response = await fetch(`/api/teams/${teamId}/reports/`, {
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRFToken": getCookie("csrftoken"),
          "Cookie": `sessionid=${getCookie("sessionid")}`,
        },
      });
      if (response.ok) {
        const reports_data = await response.json();
        setReports(reports_data);
        const dates = Object.keys(reports_data).filter((key) => key !== "team_name");
        const latestDate = dates.sort((a, b) => new Date(b) - new Date(a))[0];
        setSelectedDate(latestDate);
        setLoading(false);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (report.done === "" && report.todo === "") {
      setShowWarningAlert(true);
      setTimeout(() => {
        setShowWarningAlert(false);
      }, 3000);
      console.error("Report nemá vyplnené informácie!");
      return;
    }

    const jsonReport = JSON.stringify(report);

    localStorage.setItem('report', jsonReport);

    const method = report.id ? "PUT" : "POST";
    const message = method === "PUT" ? "Report úspešne upravený!" : "Report úspešne vytvorený!";
    setAlertMessage(message);
    const url = `/api/reports/${user.id}/latest/`;

    fetch(url, {
      method: method,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: jsonReport,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        localStorage.removeItem('report');
        setShowAlert(true);
        const id = setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        setTimeoutId(id);
        getReport();
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  };

  const handleChange = (event) => {
    setReport({
      ...report,
      [event.target.id]: event.target.value
    });
  };

  const handleClick = (date) => {
    setSelectedDate(date);
    setShowReportForm(false);
    setActiveButton(date);
  };

  const handleAddReport = () => {
    setShowReportForm(true);
    setActiveButton(report.report_day);
  };

  useEffect(() => {
    const savedReport = localStorage.getItem('report');
    if (savedReport) {
      setReport(JSON.parse(savedReport));
    }

    const path = window.location.pathname;
    const teamId = path.split('/').pop();
    const date = new Date();
    if (user.is_teacher === false && user.workweek && user.workweek.team_id
      && user.workweek.team_id === parseInt(teamId)
      && (date.getDay() !== 5 || (date.getDay() === 5 && date.getHours() < 14))) {
      getReport();
    }

    getReports();

  }, [user.is_teacher, getReport, user.workweek]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (Object.keys(report).length === 0 && report.constructor === Object) {
      setDates(prevDates => Object.keys(reports).filter((key) => key !== "team_name"));
    } else {
      setDates(prevDates => {
        const updatedDates = Object.keys(reports).filter((key) => key !== "team_name");
        updatedDates.push(report.report_day);
        return updatedDates;
      });
    }
  }, [report, reports]);

  useEffect(() => {
    setActiveButton(dates[dates.length - 1]);
  }, [dates]);


  useEffect(() => {
    if (report.id) {
      setSubmitButtonText("Upraviť odpoveď");
    } else {
      setSubmitButtonText("Pridať odpoveď");
    }
  }, [user.is_teacher, report]);

  return (
    <main className="container-xxl flex-shrink-0">
      {loading ? (
        <div className="text-center">
          <Spinner color="primary"/>
        </div>
      ) : (
        <div>
          <Row>
            <Col xs={12} lg={2}><h1>{reports.team_name}</h1></Col>
            <Col>
              {showAlert && <Alert key="alert" className="text-center m-auto" color="primary">{alertMessage}</Alert>}
              {showWarningAlert &&
                <Alert key="warningAlert" className="text-center m-auto" color="warning">Nevyplnil si informácie o
                  reporte!</Alert>}
            </Col>
          </Row>
          <Row className="row-cols-lg-auto">
            <Col xs={12} lg={2} className="mt-4">
              <div className="buttons">
                <WeekSwitcher user={user} dates={dates} report={report} activeButton={activeButton}
                              showReportButton={showReportButton} handleAddReport={handleAddReport}
                              handleClick={handleClick}/>
              </div>
            </Col>
            <Col xs={12} lg={10}>
              {showReportForm && Object.keys(report).length > 0 ? (
                <div className="report">
                  <h2 className="mt-4">Týždeň
                    od {getFormattedDate(report.report_day, 6)} do {getFormattedDate(report.report_day)}</h2>
                  <Form onSubmit={handleSubmit}>
                    <ReportFormField id="done" label="Čo sa vám podarilo urobiť tento týždeň?"
                                     text={report.done} onChange={handleChange}/>
                    <ReportFormField id="todo" label="Čo máte v úmysle urobiť budúci týždeň?"
                                     text={report.todo} onChange={handleChange}/>

                    <Button className="btn-primary" color="primary" type='submit'>
                      {submitButtonText}
                    </Button>
                  </Form>
                </div>
              ) : (
                <div className="reports">
                  <div className="mt-4">
                    {dates.length > 0 ?
                      <h2>Týždeň od {getFormattedDate(selectedDate, 6)} do {getFormattedDate(selectedDate)}</h2> :
                      <div><Alert key="alert" className="text-center m-auto" color="secondary">Zatiaľ neexistujú žiadne
                        záznamy od študentov</Alert></div>}
                  </div>
                  {reports[selectedDate]?.students.map((student, index) => (
                    <StudentReport key={index} index={index} student={student}/>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </div>)}
    </main>
  )
}

export default Reports;
