import { getCookie } from "../functions/getCookie";

export async function archiveProjectAction({ request, params }) {
  const projectId = params.projectId;
  const response = await fetch(`/api/projects/${projectId}/archive/`, {
    method: "POST",
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
    },
  });
  if (!response.ok) {
    throw new Error("Failed to archive project");
  }
  return { success: true };
}

export async function unarchiveProjectAction({ request, params }) {
  const projectId = params.projectId;
  const response = await fetch(`/api/projects/${projectId}/unarchive/`, {
    method: "POST",
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
    },
  });
  if (!response.ok) {
    throw new Error("Failed to unarchive project");
  }
  return { success: true };
}
