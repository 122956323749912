import {Link} from "react-router-dom";

export default function TeamsGroup(props) {
  return (
    <div className="mt-5">
      <h4>{props.title}</h4>
      {Object.keys(props.teams).map(year => (
        <div key={year}>
          <h6 className="mt-3">{year}</h6>
          <ul>
            {props.teams[year].map(team => (
              <li key={team.id}>
                <Link to={`/workweek/${team.id}`}>{team.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
