export async function rootLoader() {
  const [settingsResponse, userResponse] = await Promise.all([
    fetch("/api/settings/"),
    fetch("/api/user/")
  ]);
  if (!settingsResponse.ok) throw settingsResponse;
  if (!userResponse.ok) throw userResponse;

  const settings = await settingsResponse.json();
  const user = await userResponse.json();
  return { settings, user };
}
