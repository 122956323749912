import { Check, CheckAll } from "react-bootstrap-icons";

export function VersionsMarks({ versions }) {
  let versionsWithSkips = [];
  for (const version of versions) {
    versionsWithSkips[version.number - 1] = version;
  }
  let versionMarks = [];
  for (let i = 0; i < versionsWithSkips.length; i++) {
    versionMarks[i] = <VersionMark key={i} version={versionsWithSkips[i]} />;
  }
  return versionMarks;
}

function VersionMark({ version }) {
  if (version === undefined) {
    return <span className="icon-placeholder px-1"></span>;
  } else {
    return <a href={version.issue} title={version.number}
      className="text-decoration-none px-1 plausible-event-name=Version+Click">
      {version.has_comments ? <CheckAll size={24} /> : <Check size={24} />}
    </a>
  }
}

