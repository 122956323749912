import React from "react";

export default function ReportField(props) {
  return <div className="my-4 ms-4 text-block">
    <h5 className="h6 fw-bold">{props.label}</h5>
    <p>
      {props.text ? props.text.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
      }) : (
        <span className="text-body-secondary">Nevyplnené</span>
      )}
    </p>
  </div>;
}
